.sidebar {
  flex: 0.5;
  height: calc(100vh - 50px);
  background-color: rgb(251, 251, 255);
  position: sticky;
  top: 50px;
}

.sidebarWrapper {
  padding: 20px;
  color: #555;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 23px;
  margin-left:15px;
  color: rgb(138, 130, 130);
}

.sidebarList {
  list-style: none;
  padding: 10px;
}

.sidebarListItem {
  padding: 5px;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.sidebarListItem button {
  justify-content: start;
}

.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: #5724c7;
}

.sidebarListItem.active button,
.sidebarListItem:hover button {
  color: #fff !important;
}

.sidebarIcon {
  margin-right: 5px;
  font-size: 20px !important;
}

.link {
  text-decoration: none;
}
