.single {
  flex: 4;
  margin: 25px;
}

.infoContainer {
  padding: 20px;
}

.info,
.destination {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.destination {
  flex-direction: row;
}

.infoItem,
.left,
.right {
  display: flex;
  padding-top: 10px;
  align-items: center;
  font-size: 17px;
}

.left,
.right {
  padding-top: 0;
}

.left {
  margin-right: 15px;
}

.right {
  margin-left: 15px;
}

.title {
  font-size: 22px;
  font-weight: bold;
}

hr {
  width: 100%;
  height: 4px;
  margin: 15px 0;
  -webkit-box-shadow: 0px 5px 11px 2px rgba(87, 36, 199, 0.41);
  box-shadow: 0px 5px 11px 2px rgba(87, 36, 199, 0.41);
  background-color: #5724c7;
  border: none;
}

.zaposlenik {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 !important;
  width: 100%;
}

.userInfo {
  display: flex;
  text-align: center;
  flex-direction: column;
}

.userName {
  font-weight: 700;
}

.role {
  font-weight: 300;
}

.printButton {
  margin-top: 20px !important;
}
