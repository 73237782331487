.box {
  width: 335px;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  border-radius: 19px;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(15, 15, 15, 0.28);
  position: relative;
  overflow: hidden;
}

.topContainer {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 1.8rem 5em;
}

.backdrop {
  width: 160%;
  height: 550px;
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  transform: rotate(60deg);
  top: -290px;
  left: -70px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    60deg,
    rgba(2, 0, 36, 1) 2%,
    rgba(87, 36, 199, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
}

.headerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 23px;
}

.headerText {
  font-size: 30px;
  font-weight: 800;
  line-height: 1.24;
  color: #fff;
  z-index: 10;
}

.smallText {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  margin-top: 10px;
  z-index: 10;
}

.boxContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mutedLink {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  color: rgba(117, 122, 117, 0.8);
  font-weight: 400;
  text-decoration: none;
}

.mutedLink.register {
  margin-bottom: 60px !important;
}

.boldLink {
  font-size: 12px;
  color: rgba(87, 36, 199, 1);
  font-weight: 800;
  text-decoration: none;
}

.input {
  margin: 15px 15px 0px !important;
}

.input:last-of-type {
  margin-bottom: 0 !important;
}

.innerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

form {
  display: flex;
  flex-direction: column;
}

.helperText {
  margin-left: 17px !important;
  color: red !important;
}

.successText {
  margin-left: 17px !important;
  color: green !important;
}
