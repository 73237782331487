.topbar {
  width: 100%;
  height: 50px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 999;
}

.topbarWrapper {
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topRight {
  display: flex;
  align-items: center;
}

.logo {
  font-weight: bold;
  font-size: 30px;
  cursor: pointer;
  color: #5724c7;
}

.topBarIconContainer {
  color: #555;
  cursor: pointer;
  margin-top: 3px;
}

.topAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px !important;
}

.linkSetting {
  display: flex;
  align-items: center;
  color: grey;
}

.link {
  text-decoration: none;
}
