.container {
  display: flex;
}

.authContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(226, 226, 226);
}

#printWindow {
  overflow: hidden;
}
